export interface Bounds {
    north: number;
    south: number;
    east: number;
    west: number;
}

export interface FiltersType {
    location_type: undefined | string | number;
    city: undefined | string | number;
    province: undefined | string | number;
    region: undefined | string | number;
}
export enum __LocatoinType {
    Appeal_Court = 1,
    Leagal_General_Court = 2,
    Revolution_And_General_Court = 3,
    Criminal_Court_1 = 4,
    Criminal_Court_2 = 5,
    Family_Court = 6,
    Revolution_Court = 7,
    Dispute_Revolution_Council = 8,
    Police_Station = 9,
}
export interface __Citiies {
    id?: number;
    name?: string;
    province?: number;
}

export interface __Province {
    id?: number;
    name?: string;
}
