import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import { API } from 'data';
import { RuleType, Pagination as PaginationType } from 'interface';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { del, get, patch, post, responseValidator } from 'scripts/api';
import RuleCard from '../ruleCard/ruleCard.index';

const RuleList: React.FC<{ onClose: () => void; create: boolean }> = (props: {
    onClose: () => void;
    create: boolean;
}) => {
    const categoryPageSize = 12;
    const history = useHistory();
    const [category, setCategory] = useState<string | null>(
        new URLSearchParams(window.location.search).get('category'),
    );
    const [rules, setRules] = useState<PaginationType<RuleType>>();
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>();

    useEffect(() => {
        setCategory(new URLSearchParams(window.location.search).get('category'));
        setPage(1);
    }, [history.location.search]);

    useEffect(() => {
        setRules(undefined);
    }, [page, category]);

    useEffect(() => {
        if (!rules) {
            get<PaginationType<RuleType>>(API.rules, {
                page,
                page_size: categoryPageSize,
                category: category && category.length ? category : '-1',
            }).then((response) => {
                if (responseValidator(response.status) && response.data) {
                    setRules(response.data);
                } else {
                    toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
                }
            });
        }
    }, [rules]);

    const handleDeleteRule = (id) => {
        return new Promise((resolve) => {
            del(API.rules + `${id}`, {
                id,
            })
                .then((response) => {
                    if (responseValidator(response.status)) {
                        setRules(undefined);
                        toast.success('قانون حذف شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleEditRule = (id, updatedData) => {
        return new Promise((resolve) => {
            patch<RuleType>(API.rules + `${id}/`, {
                id,
                ...updatedData,
            })
                .then((response) => {
                    if (responseValidator(response.status) && response.data) {
                        const index = rules?.data?.findIndex((rule) => rule.id === id);
                        const newRules = rules ? { ...rules } : undefined;
                        if (newRules && index != undefined) {
                            newRules.data[index] = response.data;
                            setRules(newRules);
                        }
                        toast.success('قانون ویرایش شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleCreateLocation = () => {
        setLoading(true);
        post<PaginationType<RuleType>>(API.rules, {
            title: formData.title,
            category: category ? category : null,
            description: formData.description,
        }).then((response) => {
            setLoading(false);
            if (responseValidator(response.status) && response.data) {
                setRules(undefined);
                props.onClose();
                toast.success('قانون اضافه شد.');
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={props.create}
                onClose={props.onClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">افزودن قانون</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <TextField
                                label="توضیحات"
                                fullWidth
                                multiline
                                rows={2}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCreateLocation} color="primary" variant="contained">
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="content">
                <div className="rules">
                    {rules ? (
                        rules.data.length ? (
                            rules.data.map((rule) => (
                                <RuleCard
                                    rule={rule}
                                    deleteRule={handleDeleteRule}
                                    editRule={handleEditRule}
                                    key={rule.id}
                                />
                            ))
                        ) : (
                            <p style={{ padding: '20px', display: 'none' }}>no item</p>
                        )
                    ) : (
                        <>
                            {Array.from(Array(2).keys()).map((item, i) => (
                                <div className="w-100 my-2" key={i}>
                                    <Skeleton width={'100%'} height={130} />
                                </div>
                            ))}
                        </>
                    )}
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    {rules?.num_of_pages && rules.num_of_pages > 1 && (
                        <Pagination
                            color="secondary"
                            count={rules?.num_of_pages}
                            page={page}
                            onChange={(e, page) => {
                                setPage(page);
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default RuleList;
