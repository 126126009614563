import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    TextField,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { API } from 'data';
import momentJalaali from 'moment-jalaali';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-datepicker2';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { get, post, responseValidator } from 'scripts/api';
import { __Admin, __newAdmin } from './admin.interface';
import './admin.style.scss';
const Rules: React.FC = () => {
    const params = useParams<any>();
    const finishCalender = useRef<any>();
    const startCalender = useRef<any>();
    const [refresh, setRefresh] = useState<number>(1);
    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<__Admin[]>();
    const [open, setOpen] = React.useState(false);
    const [categoryTitle, setCategoryTitle] = useState('');
    const [adminDetails, setAdminDetails] = useState<__newAdmin>();
    const [startTime, setstartTime] = useState(momentJalaali());
    const [finishTime, setFinishTime] = useState(momentJalaali());
    const [inputDate, setInputDate] = useState<any>({
        start: null,
        finish: null,
    });
    const [openStartDate, setOpenStartDate] = useState<boolean>(false);
    const [closeStartDate, setCloseStartDate] = useState<boolean>(false);
    const handleClickOpenModal = () => {
        setOpen(true);
    };
    const [dateRange, setDateRange] = useState<any>({ min: undefined, max: momentJalaali().startOf('today') });
    const handleCloseModal = () => {
        setOpen(false);
    };

    useEffect(() => {
        const params = {};
        search !== '' && Object.assign(params, { search: search });
        inputDate.start && Object.assign(params, { start_date: startTime._d.toISOString() });
        inputDate.finish && Object.assign(params, { end_date: finishTime._d.toISOString() });

        get<any>(API.users, { ...params }).then((response) => {
            if (responseValidator(response.status) && response.data) {
                setUsers(response.data);
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    }, [refresh, search, inputDate]);

    const handleCreateLocation = () => {
        setLoading(true);
        post<any>(API.addAdmin, {
            phone: adminDetails?.phoneNumber,
            password: adminDetails?.password,
        })
            .then((response) => {
                if (responseValidator(response.status) && response.data) {
                    setUsers(users?.concat(response.data));
                    handleCloseModal();
                    toast.success('ادمین اضافه شد.');
                } else {
                    toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        const handler = (e) => {
            if (!finishCalender.current?.contains(e.target)) {
                setOpenStartDate(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);
    useEffect(() => {
        const handler = (e) => {
            if (!startCalender.current?.contains(e.target)) {
                setCloseStartDate(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);
    return (
        <>
            <Dialog fullWidth maxWidth="sm" open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">افزودن ادمین</DialogTitle>
                <DialogContent>
                    <div className="row p-4">
                        <div className="col-12">
                            <TextField
                                autoFocus
                                label="شماره همراه"
                                fullWidth
                                onChange={(e) => setAdminDetails({ ...adminDetails, phoneNumber: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-5">
                            <TextField
                                autoFocus
                                label="پسورد"
                                fullWidth
                                onChange={(e) => setAdminDetails({ ...adminDetails, password: e.target.value })}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="ml-5 mt-2 mb-2">
                        <Button disabled={loading} onClick={handleCreateLocation} color="primary" variant="contained">
                            افزودن
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <div className="all-admin-page">
                <div className="header">
                    <div className="title">
                        <h3>ادمین ها</h3>
                        <small>{categoryTitle}</small>
                    </div>
                    <div className="filter">
                        <FormControl variant="outlined">
                            <InputLabel htmlFor="input-with-icon-adornment">از تاریخه </InputLabel>
                            <Input
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <span className="material-icons-outlined">keyboard_arrow_down</span>
                                    </InputAdornment>
                                }
                                placeholder="بازه شروع را انتخاب کنید"
                                onClick={() => setCloseStartDate(true)}
                                disabled={true}
                                value={inputDate.start}
                            />
                            {closeStartDate && (
                                <div ref={startCalender} className="filter-days">
                                    <Calendar
                                        value={startTime}
                                        isGregorian={false}
                                        max={dateRange.max}
                                        onChange={(value) => {
                                            setstartTime(value);
                                            setInputDate({
                                                ...inputDate,
                                                start: value._d.toLocaleDateString('fa'),
                                            });
                                            setDateRange({
                                                ...dateRange,
                                                min: value,
                                            });
                                            setCloseStartDate(false);
                                        }}
                                    />
                                </div>
                            )}
                        </FormControl>

                        <FormControl variant="outlined">
                            <InputLabel htmlFor="input-with-icon-adornment">تا تاریخه </InputLabel>
                            <Input
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <span className="material-icons-outlined">keyboard_arrow_down</span>
                                    </InputAdornment>
                                }
                                placeholder="بازه پایانی را انتخاب کنید"
                                onClick={() => setOpenStartDate(true)}
                                disabled={true}
                                value={inputDate.finish}
                            />
                            {openStartDate && (
                                <div ref={finishCalender} className="filter-days">
                                    <Calendar
                                        value={finishTime}
                                        isGregorian={false}
                                        min={dateRange.min}
                                        max={momentJalaali().startOf('today')}
                                        onChange={(value) => {
                                            setFinishTime(value);
                                            setInputDate({
                                                ...inputDate,
                                                finish: value._d.toLocaleDateString('fa'),
                                            });
                                            setDateRange({
                                                ...dateRange,
                                                max: value,
                                            });
                                            setOpenStartDate(false);
                                        }}
                                    />
                                </div>
                            )}
                        </FormControl>

                        <FormControl>
                            <InputLabel htmlFor="input-with-icon-adornment">جستجو</InputLabel>
                            <Input
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <i className="material-icons">search</i>
                                    </InputAdornment>
                                }
                                placeholder="جستجو کنید"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </FormControl>
                        <Button color="secondary" onClick={handleClickOpenModal}>
                            افزودن +
                        </Button>
                    </div>
                </div>
                <div className="content">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>شماره همراه</th>
                                <th>ماده های ایجاد شده</th>
                                <th>قوانین ایجاد شده</th>
                                <th>عملیات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users ? (
                                users.length ? (
                                    users.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.admin?.phone ? user.admin.phone : 'ثبت نشده'}</td>
                                            <td>{user.created_articles}</td>
                                            <td> {user.created_rules}</td>
                                            <td className="icons_danger">
                                                <i className="material-icons-outlined">delete</i>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    'no result'
                                )
                            ) : (
                                <>
                                    {Array.from(Array(2).keys()).map((item, i) => (
                                        <tr key={i}>
                                            {Array.from(Array(4).keys()).map((item, i) => (
                                                <td key={i}>
                                                    <Skeleton height={50} />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Rules;
