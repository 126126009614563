import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GroupCardProps } from './groupCard.interface';
import './groupCard.style.scss';
import { ImageAttachment } from 'interface';
import { upload } from 'scripts/api';
import { API, RoutePath } from 'data';
const GroupCard: React.FC<GroupCardProps> = (props: GroupCardProps) => {
    const [deleteModalStatus, setDeleteModalStatus] = React.useState(false);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = useState<any>({
        title: props.group.title,
        image: props.group.image,
        description: props.group.description,
        parent: props.group.parent,
    });
    const { rule } = useParams<any>();
    const [logos, setLogos] = useState<ImageAttachment | undefined>();
    const [dragStatus, setDragStatus] = useState<string>('');
    const [imageName, setImageName] = useState<string | undefined>();

    const handleClickOpenDeleteModal = () => {
        setDeleteModalStatus(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalStatus(false);
    };

    const handleClickOpenEditModal = () => {
        setEditModalStatus(true);
    };

    const handleCloseEditModal = () => {
        setEditModalStatus(false);
    };

    const handleDeleteLocation = (id) => {
        setLoading(true);
        props.deleteGroup(id).then(() => {
            setLoading(false);
            handleCloseDeleteModal();
        });
    };

    const handleEditLocation = () => {
        if (props.group.id) {
            setLoading(true);
            props.editGroup(props.group.id, formData).then(() => {
                setLoading(false);
                handleCloseEditModal();
            });
        }
    };
    function onDropHandler(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files.length) {
            onChangeImageHandler(e.dataTransfer.files);
        }
    }
    function onChangeImageHandler(e: FileList) {
        return new Promise<ImageAttachment>(() => {
            const form = new FormData();
            form.append('image', e[0], e[0].name);
            setImageName(e[0].name);
            upload(API.image_Attachment, form, (e) => {
                //  setLoadingAvatarImg(true);
            }).promise.then((res: any) => {
                //  setLoadingAvatarImg(false);
                if (res.data) {
                    setLogos(res.data);
                    setFormData({ ...formData, image: res.data.id });
                }
            });
        });
    }
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={editModalStatus}
                onClose={handleCloseEditModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">ویرایش دسته بندی</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ title: e.target.value })}
                                value={formData.title}
                            />
                        </div>

                        {/*<div className="col-md-6 " style={{ marginTop: '-8px' }}>*/}
                        {/*    <InputLabel id="demo-simple-select-label" className="mb-2">*/}
                        {/*        زیرمجموعه*/}
                        {/*    </InputLabel>*/}
                        {/*    <Select*/}
                        {/*        labelId="demo-simple-select-label"*/}
                        {/*        id="demo-simple-select"*/}
                        {/*        value={formData.parent}*/}
                        {/*        onChange={(e) => setFormData({ ...formData, parent: e.target.value })}*/}
                        {/*        fullWidth*/}
                        {/*    >*/}
                        {/*        {props.groupFild*/}
                        {/*            ? props.groupFild.length*/}
                        {/*                ? props.groupFild.map((category) => (*/}
                        {/*                      <MenuItem key={category.id} value={category.id}>*/}
                        {/*                          {category.title}*/}
                        {/*                      </MenuItem>*/}
                        {/*                  ))*/}
                        {/*                : null*/}
                        {/*            : null}*/}
                        {/*    </Select>*/}
                        {/*</div>*/}
                        <div className="col-md-12 mt-4">
                            <TextField
                                autoFocus
                                label="توضیحات"
                                type="color"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                multiline
                                rows={1}
                                value={formData.description}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <div
                                onDrop={onDropHandler}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onDragEnter={() => setDragStatus('enterDrag')}
                                onDragLeave={() => setDragStatus('')}
                                className={`upload_icons ${!logos && dragStatus}`}
                            >
                                {logos && (
                                    <div className="d-flex w-100 justify-content-between align-items-center p-3">
                                        <div className=" d-flex justify-content-center align-items-center">
                                            <span>نام :‌</span>
                                            <span>{imageName}</span>
                                        </div>
                                        <Button
                                            onClick={() => {
                                                setLogos(undefined);
                                                setDragStatus('');
                                                setImageName(undefined);
                                                setFormData({ ...formData, image: undefined });
                                            }}
                                        >
                                            <div className=" d-flex justify-content-center align-items-center">
                                                <span>حذف</span>
                                                <i className="material-icons-outlined">delete</i>
                                            </div>
                                        </Button>
                                    </div>
                                )}

                                <div className="upload_part d-flex w-100 justify-content-center align-items-center">
                                    {!logos && (
                                        <label htmlFor="update_icons">
                                            <div>عکس را به داخل کادر بکشید یا کلیک کنید</div>
                                        </label>
                                    )}

                                    <input
                                        id="update_icons"
                                        type="file"
                                        className="inputs_attachment"
                                        onChange={(e) => {
                                            if (e.target.files?.length) {
                                                onChangeImageHandler(e.target.files);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleEditLocation} color="primary" variant="contained">
                        ویرایش
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={deleteModalStatus}
                keepMounted
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">حذف دسته بندی</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        آیا از حذف این دسته بندی اطمینان دارید ؟
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCloseDeleteModal} color="primary" variant="contained">
                        انصراف
                    </Button>
                    <Button
                        onClick={() => handleDeleteLocation(props.group.id)}
                        disabled={loading}
                        color="primary"
                        variant="outlined"
                    >
                        حذف
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={props.group?.image ? 'category' : 'category category_with_no_bg'}>
                {props.group?.image && (
                    <div style={{ background: `url(${props.group?.image})` }} className="bg_for_category"></div>
                )}

                <Link to={RoutePath.rule(rule) + `?group=${props.group.id}`}>
                    <h3>{props.group.title}</h3>
                    <p>{props.group.description}</p>
                </Link>
                <div className="actions">
                    <i className="material-icons" onClick={handleClickOpenDeleteModal}>
                        delete
                    </i>
                    <i className="material-icons" onClick={handleClickOpenEditModal}>
                        edit
                    </i>
                </div>
            </div>
        </>
    );
};

export default GroupCard;
