import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import { API } from 'data';
import { Pagination as PaginationType, ArticleGroupType } from 'interface';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { del, get, patch, post, responseValidator, upload } from 'scripts/api';
import GroupFieldCard from '../groupCard/groupCard.index';
const GroupsComp: React.FC<{ onClose: () => void; create: boolean }> = (props: {
    onClose: () => void;
    create: boolean;
}) => {
    const categoryPageSize = 12;
    const history = useHistory();
    const { rule } = useParams<any>();
    const [group, setGroup] = useState<string | null>(new URLSearchParams(window.location.search).get('group'));
    const [groups, setGroups] = useState<PaginationType<ArticleGroupType>>();
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({
        title: '',
        description: '',
    });

    useEffect(() => {
        setGroup(new URLSearchParams(window.location.search).get('group'));
        setPage(1);
    }, [history.location.search]);

    useEffect(() => {
        setGroups(undefined);
    }, [page, group]);

    useEffect(() => {
        if (!groups) {
            get<PaginationType<ArticleGroupType>>(API.article_group, {
                page,
                page_size: categoryPageSize,
                parent: group && group.length ? group : '-1',
                rule,
            }).then((response) => {
                if (responseValidator(response.status) && response.data) {
                    setGroups(response.data);
                } else {
                    toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
                }
            });
        }
    }, [groups]);

    const handleDeleteCategory = (id) => {
        return new Promise((resolve) => {
            del(API.article_group + `${id}`, {
                id,
            })
                .then((response) => {
                    if (responseValidator(response.status)) {
                        setGroups(undefined);
                        toast.success('گروه بندی حذف شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleEditCategory = (id, updatedData) => {
        return new Promise((resolve) => {
            patch<ArticleGroupType>(API.article_group + `${id}/`, {
                id,
                ...updatedData,
            })
                .then((response) => {
                    if (responseValidator(response.status) && response.data) {
                        const index = groups?.data?.findIndex((group) => group.id === id);
                        const newCategories = groups ? { ...groups } : undefined;
                        if (newCategories && index != undefined) {
                            newCategories.data[index] = response.data;
                            setGroups(newCategories);
                        }
                        toast.success('گروه بندی ویرایش شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };
    const handleCreateCategory = () => {
        setLoading(true);
        post<ArticleGroupType>(API.article_group, {
            ...formData,
            rule,
            parent: group && group.length ? group : undefined,
        }).then((response) => {
            setLoading(true);
            if (responseValidator(response.status) && response.data) {
                setGroups(undefined);
                props.onClose();
                toast.success('گروه بندی اضافه شد.');
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    };

    return (
        <>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={props.create}
                onClose={props.onClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">افزودن گروه بندی</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <TextField
                                autoFocus
                                label="توضیحات"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                multiline
                                rows={1}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCreateCategory} color="primary" variant="contained">
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="content">
                <div className="groups">
                    {groups ? (
                        groups.data.length > 0 ? (
                            groups.data.map((group) => (
                                <GroupFieldCard
                                    group={group}
                                    deleteGroup={handleDeleteCategory}
                                    editGroup={handleEditCategory}
                                    key={group.id}
                                />
                            ))
                        ) : (
                            <p style={{ padding: '20px', display: 'none' }}>no item</p>
                        )
                    ) : (
                        <>
                            {Array.from(Array(categoryPageSize).keys()).map((item, i) => (
                                <div className="mx-2" key={i}>
                                    <Skeleton width={250} height={200} />
                                </div>
                            ))}
                        </>
                    )}
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    {groups?.num_of_pages && groups.num_of_pages > 1 && (
                        <Pagination
                            color="secondary"
                            count={groups?.num_of_pages}
                            page={page}
                            onChange={(e, page) => {
                                setPage(page);
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default GroupsComp;
