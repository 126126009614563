import ENV from 'env.json';
type apiParams = string | number;

export const __API = {
    locations: `${ENV.api}/location/location/`,
    categories: `${ENV.api}/rule/category/`,
    rules: `${ENV.api}/rule/rule/`,
    articles: `${ENV.api}/rule/article/`,
    auth: `${ENV.api}/account/vekalauth/callback/`,
    new_auth: `${ENV.auth_api}/oauth/token/`,
    me: `${ENV.auth_api}/api/account/me/`,
    logout: `${ENV.auth_api}/api/account/user/logout/`,
    image_Attachment: `${ENV.api}/account/image_attachment/`,
    users: `${ENV.api}/account/users/admins_created_contents_report/`,
    addAdmin: `${ENV.auth_api}/api/account/user/create_admin_user/`,
    city: `${ENV.api}/location/cities/`,
    province: `${ENV.api}/location/provinces/`,
    advisory_opinion: `${ENV.api}/rule/advisory_opinion/`,
    convention: `${ENV.api}/rule/convention`,
    judicial_decision: `${ENV.api}/rule/judicial_decision/`,
    legal_doctrine: `${ENV.api}/rule/legal_doctrine/`,
    article_group: `${ENV.api}/rule/article_group/`,
};
