import { Button, FormControl, Input, InputAdornment, InputLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './rule.style.scss';
import GroupsComp from './groups/groups.index';
import ArticleList from './articles/articles.index';
const Rule: React.FC = () => {
    const history = useHistory();
    const [addCategory, setAddCategory] = useState<boolean>(false);
    const [addRule, setAddRule] = useState<boolean>(false);

    return (
        <div className="vekalapp-admin-rule-page">
            <div className="header">
                <FormControl>
                    <InputLabel htmlFor="input-with-icon-adornment">جستجو</InputLabel>
                    <Input
                        id="input-with-icon-adornment"
                        startAdornment={
                            <InputAdornment position="start">
                                <i className="material-icons">search</i>
                            </InputAdornment>
                        }
                        placeholder="جستجو کنید"
                    />
                </FormControl>
                <span className="spacer"></span>
                <Button
                    color="secondary"
                    onClick={() => {
                        setAddRule(true);
                    }}
                >
                    افزودن ماده +
                </Button>
                <Button
                    color="secondary"
                    onClick={() => {
                        setAddCategory(true);
                    }}
                >
                    افزودن گروه بندی +
                </Button>
            </div>
            <GroupsComp create={addCategory} onClose={() => setAddCategory(false)} />
            <ArticleList create={addRule} onClose={() => setAddRule(false)} />
        </div>
    );
};

export default Rule;
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
