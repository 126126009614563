import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { AuthStatus, ReduxState } from 'interface';
import { connect, ConnectedProps } from 'react-redux';
import { createTheme, jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { create } from 'jss';
import { ToastContainer } from 'react-toastify';
import rtl from 'jss-rtl';
import Locations from './locations/locations.index';
import Base from './base/base.index';
import Login from './login/login.index';
import Rules from './rules/rules.index';
import Admins from './admin/admin.index';
import AdvisoryOpinions from './advisory_opinion/advisory_opinion.index';
import Conventions from './convention/convention.index';
import JudicialDecisions from './judicial_decision/judicial_decision.index';
import Rule from './rule/rule.index';
import LegalDoctrines from './legal_doctrine/legal_doctrine.index';
import VekalappInit from './vekalapp.script';
import { RoutePath } from 'data';

const Vekalapp: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const theme = createTheme({
        direction: 'rtl',
        palette: {
            primary: {
                light: '#14154e',
                main: '#0E0F3B',
                dark: '#090a24',
                contrastText: '#fff',
            },
            secondary: {
                light: '#5696fd',
                main: '#3B86FF',
                dark: '#2c68c7',
                contrastText: '#fff',
            },
        },
    });
    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

    useEffect(() => {
        VekalappInit(props.dispatch).then();
    }, []);

    return (
        <StylesProvider jss={jss}>
            <ThemeProvider theme={theme}>
                <Router>
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Switch>
                        {props.isAuth === AuthStatus.inValid && (
                            <Switch>
                                <Route path={RoutePath.login} component={Login} />
                                <Redirect path="*" to={RoutePath.login} />
                            </Switch>
                        )}
                        {props.isAuth === AuthStatus.valid && (
                            <Base>
                                <Switch>
                                    <Route path={RoutePath.locations} component={Locations} />
                                    <Route path={RoutePath.rule()} component={Rule} />
                                    <Route path={RoutePath.rules} component={Rules} />
                                    <Route path={RoutePath.advisory_opinion} component={AdvisoryOpinions} />
                                    <Route path={RoutePath.admins} component={Admins} />
                                    <Route path={RoutePath.convention} component={Conventions} />
                                    <Route path={RoutePath.judicial_decision} component={JudicialDecisions} />
                                    <Route path={RoutePath.legal_doctrine} component={LegalDoctrines} />
                                </Switch>
                            </Base>
                        )}
                    </Switch>
                </Router>
            </ThemeProvider>
        </StylesProvider>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
});

const connector = connect(mapStateToProps);
export default connector(Vekalapp);
