import { __LocatoinType } from './locations.interface';
export const locationType: any = [
    { title: 'دادگاه تجدید نظر', payload: __LocatoinType.Appeal_Court },
    { title: 'دادگاه عمومی حقوقی', payload: __LocatoinType.Leagal_General_Court },
    { title: 'دادسرا عمومی و انقلاب ', payload: __LocatoinType.Revolution_And_General_Court },
    { title: 'دادگاه کیفری 1', payload: __LocatoinType.Criminal_Court_1 },
    { title: 'دادگاه کیفری 2', payload: __LocatoinType.Criminal_Court_2 },
    { title: 'دادگاه خانواده', payload: __LocatoinType.Family_Court },
    { title: 'دادگاه انقلاب ', payload: __LocatoinType.Revolution_Court },
    { title: 'شورای حل اختلاف', payload: __LocatoinType.Dispute_Revolution_Council },
    { title: 'کلانتری', payload: __LocatoinType.Police_Station },
];
