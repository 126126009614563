import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { ConventionInterface } from '../convention.interface';
import { ConventionInterfaceCardProps } from './conventionCard.interface';

const ConventionCard: React.FC<ConventionInterfaceCardProps> = (props: ConventionInterfaceCardProps) => {
    const [deleteModalStatus, setDeleteModalStatus] = React.useState(false);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = useState<ConventionInterface>({
        id: props.conventionSingle.id,
        created_at: props.conventionSingle.created_at,
        title: props.conventionSingle.title,
        description: props.conventionSingle.description,
        creator: props.conventionSingle.creator,
        article: props.conventionSingle.article,
    });

    const handleClickOpenDeleteModal = () => {
        setDeleteModalStatus(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalStatus(false);
    };

    const handleClickOpenEditModal = () => {
        setEditModalStatus(true);
    };

    const handleCloseEditModal = () => {
        setEditModalStatus(false);
    };

    const handleDeleteLocation = (id) => {
        setLoading(true);
        props.deleteconvention(id).then(() => {
            setLoading(false);
            handleCloseDeleteModal();
        });
    };

    const handleEditLocation = () => {
        setLoading(true);
        if (props.conventionSingle.id) {
            props.editconvention(props.conventionSingle.id, formData).then(() => {
                setLoading(false);
                handleCloseEditModal();
            });
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={editModalStatus}
                onClose={handleCloseEditModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">ویرایش دسته بندی</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>

                        <div className="col-12 mt-4 ">
                            <TextField
                                autoFocus
                                label="توضیحات"
                                type="color"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                multiline
                                rows={1}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleEditLocation} color="primary" variant="contained">
                        ویرایش
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={deleteModalStatus}
                keepMounted
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">حذف دسته بندی</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        آیا از حذف این دسته بندی اطمینان دارید ؟
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCloseDeleteModal} color="primary" variant="contained">
                        انصراف
                    </Button>
                    <Button
                        onClick={() => handleDeleteLocation(props.conventionSingle.id)}
                        disabled={loading}
                        color="primary"
                        variant="outlined"
                    >
                        حذف
                    </Button>
                </DialogActions>
            </Dialog>
            <tr>
                <td>{props.conventionSingle.title}</td>
                <td>{props.conventionSingle.description}</td>

                <td>
                    <i className="material-icons-outlined" onClick={handleClickOpenDeleteModal}>
                        delete
                    </i>
                    <i className="material-icons-outlined" onClick={handleClickOpenEditModal}>
                        edit
                    </i>
                </td>
            </tr>
        </>
    );
};

export default ConventionCard;
