import { Type } from './judicial_decision.interface';
export const JudicalType: any = [
    { title: 'آرای وحدت رویه هیئت عمومی دیوان عالی کشور', payload: 1 },
    { title: 'آرای اصراری هیئت عمومی دیوان عالی کشور', payload: 2 },
    { title: 'آرای شعب دیوان عالی', payload: 3 },
    { title: 'آرای هیئت عمومی دیوان عدالت اداری', payload: 4 },
    { title: 'آرای شعب دیوان عدالت اداری', payload: 5 },
    { title: 'آرای شعب محاکم تجدید نظر', payload: 6 },
    { title: 'آرای شعب بدوی', payload: 7 },
    { title: 'آرای داوری', payload: 8 },
];
