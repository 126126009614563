import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    Select,
    TextField,
    MenuItem,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-datepicker2';
import { JudicialDecisionCardPropsInterFace } from './judicial_decisionCard.interface';
import { JudicialDecisionInterFace } from '../judicial_decision.interface';
import { JudicalType } from '../judicial_decision.data';
const JudicialDecisionCard: React.FC<JudicialDecisionCardPropsInterFace> = (
    props: JudicialDecisionCardPropsInterFace,
) => {
    const DateCalender2 = useRef<any>();
    const [deleteModalStatus, setDeleteModalStatus] = React.useState(false);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [closeStartDate, setCloseStartDate] = useState<boolean>(false);
    const [formData, setFormData] = useState<JudicialDecisionInterFace>(props.judicialDecisionSingle);

    const handleClickOpenDeleteModal = () => {
        setDeleteModalStatus(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalStatus(false);
    };

    const handleClickOpenEditModal = () => {
        setEditModalStatus(true);
    };

    const handleCloseEditModal = () => {
        setEditModalStatus(false);
    };

    const handleDeleteLocation = (id) => {
        setLoading(true);
        props.deleteJudicialDecision(id).then(() => {
            setLoading(false);
            handleCloseDeleteModal();
        });
    };

    const handleEditLocation = () => {
        setLoading(true);
        if (props.judicialDecisionSingle.id) {
            props.editJudicialDecision(props.judicialDecisionSingle.id, formData).then(() => {
                setLoading(false);
                handleCloseEditModal();
            });
        }
    };
    useEffect(() => {
        const handler = (e) => {
            if (!DateCalender2.current?.contains(e.target)) {
                setCloseStartDate(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={editModalStatus}
                onClose={handleCloseEditModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">ویرایش دسته بندی</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>

                        <div className="col-md-6 " style={{ marginTop: '-8px' }}>
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-md-6">
                            {' '}
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">نوع</InputLabel>
                                <Select
                                    onChange={(e: any) => setFormData({ ...formData, type: e.target.value })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                >
                                    {JudicalType.map((type, index) => (
                                        <MenuItem key={index} value={type.payload}>
                                            {type.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>{' '}
                        </div>

                        <div className="col-12 mt-4">
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="input-with-icon-adornment">از تاریخه </InputLabel>
                                <Input
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <span className="material-icons-outlined">keyboard_arrow_down</span>
                                        </InputAdornment>
                                    }
                                    placeholder="بازه شروع را انتخاب کنید"
                                    onClick={() => setCloseStartDate(true)}
                                    disabled={true}
                                    value={formData.issue_date}
                                />
                                {closeStartDate && (
                                    <div ref={DateCalender2} className="filter-days">
                                        <Calendar
                                            value={formData.issue_date}
                                            isGregorian={false}
                                            onChange={(value) => {
                                                setFormData({ ...formData, issue_date: value._d });
                                                setCloseStartDate(false);
                                            }}
                                        />
                                    </div>
                                )}
                            </FormControl>
                        </div>

                        <div className="col-md-6 ">
                            <TextField
                                autoFocus
                                label="شماره شعبه"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>

                        <div className="col-md-6 ">
                            <TextField
                                autoFocus
                                label="موضوع"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleEditLocation} color="primary" variant="contained">
                        ویرایش
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={deleteModalStatus}
                keepMounted
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">حذف دسته بندی</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        آیا از حذف این دسته بندی اطمینان دارید ؟
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCloseDeleteModal} color="primary" variant="contained">
                        انصراف
                    </Button>
                    <Button
                        onClick={() => handleDeleteLocation(props.judicialDecisionSingle.id)}
                        disabled={loading}
                        color="primary"
                        variant="outlined"
                    >
                        حذف
                    </Button>
                </DialogActions>
            </Dialog>
            <tr>
                <td>{props.judicialDecisionSingle.title}</td>
                <td>{props.judicialDecisionSingle.description}</td>
                <td>
                    <>
                        {props.judicialDecisionSingle.type &&
                            JudicalType.find((item: any) => item.payload === props.judicialDecisionSingle.type).title}
                    </>
                </td>
                <td>
                    <i className="material-icons-outlined" onClick={handleClickOpenDeleteModal}>
                        delete
                    </i>
                    <i className="material-icons-outlined" onClick={handleClickOpenEditModal}>
                        edit
                    </i>
                </td>
            </tr>
        </>
    );
};

export default JudicialDecisionCard;
