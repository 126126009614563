import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { ArticleCardProps } from './articleCard.interface';
const editorConfiguration = {
    toolbar: {
        items: [
            'heading',
            '|',
            'fontsize',
            '|',
            'alignment',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'superscript',
            '|',
            'link',
            '|',
            'outdent',
            'indent',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '|',
            'code',
            'codeBlock',
            '|',
            'blockQuote',
            '|',
            'undo',
            'redo',
        ],
        shouldNotGroupWhenFull: true,
    },
};

const ArticleCard: React.FC<ArticleCardProps> = (props: ArticleCardProps) => {
    const [deleteModalStatus, setDeleteModalStatus] = React.useState(false);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = useState<any>({
        title: props.article.title,
        description: props.article.description,
    });

    const handleClickOpenDeleteModal = () => {
        setDeleteModalStatus(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalStatus(false);
    };

    const handleClickOpenEditModal = (e) => {
        setEditModalStatus(true);
    };

    const handleCloseEditModal = () => {
        setEditModalStatus(false);
    };

    const handleDeleteLocation = (id) => {
        setLoading(true);
        props.deleteArticle(id).then(() => {
            setLoading(false);
            handleCloseDeleteModal();
        });
    };

    const handleEditLocation = () => {
        setLoading(true);
        props.editArticle(props.article.id, formData).then(() => {
            setLoading(false);
            handleCloseEditModal();
        });
    };

    return (
        <>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={editModalStatus}
                onClose={handleCloseEditModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">ویرایش ماده</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                value={formData.title}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data={formData.description}
                                onReady={(editor) => {
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    console.log({ event, editor, data });
                                    setFormData({ ...formData, description: data });
                                }}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleEditLocation} color="primary" variant="contained">
                        ویرایش
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={deleteModalStatus}
                keepMounted
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">حذف ماده</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        آیا از حذف این ماده اطمینان دارید ؟
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCloseDeleteModal} color="primary" variant="contained">
                        انصراف
                    </Button>
                    <Button
                        onClick={() => handleDeleteLocation(props.article.id)}
                        disabled={loading}
                        color="primary"
                        variant="outlined"
                    >
                        حذف
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="ruleCard">
                <h3>{props.article.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: props.article.description }} />
                <div className="actions">
                    <i className="material-icons" onClick={handleClickOpenDeleteModal}>
                        delete
                    </i>
                    <i className="material-icons" onClick={handleClickOpenEditModal}>
                        edit
                    </i>
                </div>
            </div>
        </>
    );
};

export default ArticleCard;
