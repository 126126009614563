import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import { API } from 'data';
import { ImageAttachment, Pagination as PaginationType, RuleCategoryType } from 'interface';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { del, get, patch, post, responseValidator, upload } from 'scripts/api';
import CategoryCard from '../categoryCard/categoryCard.index';
const CategoriesList: React.FC<{ onClose: () => void; create: boolean }> = (props: {
    onClose: () => void;
    create: boolean;
}) => {
    const categoryPageSize = 12;
    const history = useHistory();
    const [category, setCategory] = useState<string | null>(
        new URLSearchParams(window.location.search).get('category'),
    );
    const [categories, setCategories] = useState<PaginationType<RuleCategoryType>>();
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({
        title: '',
        image: undefined,
        description: '',
    });
    const [dragStatus, setDragStatus] = useState<string>('');
    const [imageName, setImageName] = useState<string>('');
    const [logos, setLogos] = useState<ImageAttachment>();

    useEffect(() => {
        setCategory(new URLSearchParams(window.location.search).get('category'));
        setPage(1);
    }, [history.location.search]);

    useEffect(() => {
        setCategories(undefined);
    }, [page, category]);

    useEffect(() => {
        if (!categories) {
            get<PaginationType<RuleCategoryType>>(API.categories, {
                page,
                page_size: categoryPageSize,
                parent: category && category.length ? category : '-1',
            }).then((response) => {
                if (responseValidator(response.status) && response.data) {
                    setCategories(response.data);
                } else {
                    toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
                }
            });
        }
    }, [categories]);

    const handleDeleteCategory = (id) => {
        return new Promise((resolve) => {
            del(API.categories + `${id}`, {
                id,
            })
                .then((response) => {
                    if (responseValidator(response.status)) {
                        setCategories(undefined);
                        toast.success('دسته بندی حذف شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleEditCategory = (id, updatedData) => {
        return new Promise((resolve) => {
            patch<RuleCategoryType>(API.categories + `${id}/`, {
                id,
                ...updatedData,
            })
                .then((response) => {
                    if (responseValidator(response.status) && response.data) {
                        const index = categories?.data?.findIndex((category) => category.id === id);
                        const newCategories = categories ? { ...categories } : undefined;
                        if (newCategories && index != undefined) {
                            newCategories.data[index] = response.data;
                            setCategories(newCategories);
                        }
                        toast.success('دسته بندی ویرایش شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };
    const handleCreateCategory = () => {
        setLoading(true);
        post<RuleCategoryType>(API.categories, {
            ...formData,
            parent: category && category.length ? category : undefined,
            tags: [],
        }).then((response) => {
            setLoading(true);
            if (responseValidator(response.status) && response.data) {
                setCategories(undefined);
                props.onClose();
                toast.success('دسته بندی اضافه شد.');
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    };

    function onDropHandler(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files.length) {
            onChangeImageHandler(e.dataTransfer.files);
        }
    }
    function onChangeImageHandler(e: FileList) {
        return new Promise<ImageAttachment>(() => {
            const form = new FormData();
            form.append('image', e[0], e[0].name);
            form.append('alt', e[0].name);

            upload(API.image_Attachment, form, (e) => {
                //  setLoadingAvatarImg(true);
            }).promise.then((res: any) => {
                if (res.data) {
                    setLogos(res.data);
                    setFormData({ ...formData, image: res.data.id });
                    setImageName(e[0].name);
                } else {
                    toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
                }
            });
        });
    }
    return (
        <>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={props.create}
                onClose={props.onClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">افزودن دسته بندی</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <TextField
                                autoFocus
                                label="توضیحات"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                multiline
                                rows={1}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <div
                                onDrop={onDropHandler}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onDragEnter={() => setDragStatus('enterDrag')}
                                onDragLeave={() => setDragStatus('')}
                                className={`upload_icons ${!logos && dragStatus}`}
                            >
                                {logos && (
                                    <div className="d-flex w-100 justify-content-between align-items-center p-3">
                                        <div className=" d-flex justify-content-center align-items-center">
                                            <span>نام :‌</span>
                                            <span>{imageName}</span>
                                        </div>
                                        <Button
                                            onClick={() => {
                                                setLogos(undefined);
                                                setDragStatus('');
                                            }}
                                        >
                                            <div className=" d-flex justify-content-center align-items-center">
                                                <span>حذف</span>
                                                <i className="material-icons-outlined">delete</i>
                                            </div>
                                        </Button>
                                    </div>
                                )}

                                <div className="upload_part d-flex w-100 justify-content-center align-items-center">
                                    {!logos && (
                                        <label htmlFor="update_icons">
                                            <div>عکس را به داخل کادر بکشید یا کلیک کنید</div>
                                        </label>
                                    )}

                                    <input
                                        id="update_icons"
                                        type="file"
                                        className="inputs_attachment"
                                        onChange={(e) => {
                                            if (e.target.files?.length) {
                                                onChangeImageHandler(e.target.files);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCreateCategory} color="primary" variant="contained">
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="content">
                <div className="categories">
                    {categories ? (
                        categories.data.length > 0 ? (
                            categories.data.map((category) => (
                                <CategoryCard
                                    category={category}
                                    deleteCategory={handleDeleteCategory}
                                    editCategory={handleEditCategory}
                                    key={category.id}
                                />
                            ))
                        ) : (
                            <p style={{ padding: '20px', display: 'none' }}>no item</p>
                        )
                    ) : (
                        <>
                            {Array.from(Array(categoryPageSize).keys()).map((item, i) => (
                                <div className="mx-2" key={i}>
                                    <Skeleton width={250} height={200} />
                                </div>
                            ))}
                        </>
                    )}
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    {categories?.num_of_pages && categories.num_of_pages > 1 && (
                        <Pagination
                            color="secondary"
                            count={categories?.num_of_pages}
                            page={page}
                            onChange={(e, page) => {
                                setPage(page);
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default CategoriesList;
