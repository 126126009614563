import { Button, FormControl, Input, InputAdornment, InputLabel, Typography } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './rules.style.scss';
import CategoriesList from './categoryList/categoryList.index';
import RuleList from './ruleList/ruleList.index';
const Rules: React.FC = () => {
    const history = useHistory();
    const [category, setCategory] = useState<string | null>(
        new URLSearchParams(window.location.search).get('category'),
    );
    const [addCategory, setAddCategory] = useState<boolean>(false);
    const [addRule, setAddRule] = useState<boolean>(false);

    useEffect(() => {
        setCategory(new URLSearchParams(window.location.search).get('category'));
    }, [history.location.search]);

    return (
        <div className="vekalapp-admin-rules-page">
            <div className="header">
                <FormControl>
                    <InputLabel htmlFor="input-with-icon-adornment">جستجو</InputLabel>
                    <Input
                        id="input-with-icon-adornment"
                        startAdornment={
                            <InputAdornment position="start">
                                <i className="material-icons">search</i>
                            </InputAdornment>
                        }
                        placeholder="جستجو کنید"
                    />
                </FormControl>
                <span className="spacer"></span>
                {category ? (
                    <Button
                        color="secondary"
                        onClick={() => {
                            setAddRule(true);
                        }}
                    >
                        افزودن قوانین +
                    </Button>
                ) : null}
                <Button
                    color="secondary"
                    onClick={() => {
                        setAddCategory(true);
                    }}
                >
                    افزودن دسته بندی +
                </Button>
            </div>
            <CategoriesList create={addCategory} onClose={() => setAddCategory(false)} />
            <RuleList create={addRule} onClose={() => setAddRule(false)} />
        </div>
    );
};

export default Rules;
function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const theme = createMuiTheme({
    overrides: {
        MuiTabs: {
            indicator: {
                backgroundColor: '#212121',
            },
        },
        MuiTab: {
            root: {
                color: '#4d4f5c !important',
                fontSize: '22px',
                '&$selected': {
                    backgroundColor: 'transparent',
                    color: '#4d4f5c',
                    '&:hover': {
                        backgroundColor: '#f5f5f5',
                        color: '#4d4f5c',
                    },
                },
            },
        },
    },
});
