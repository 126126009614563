import L from 'leaflet';
import red from '../../assets/images/pin1.png';
import green from '../../assets/images/pin2.png';
import blue from '../../assets/images/pin3.png';
import orange from '../../assets/images/pin4.png';
import purple from '../../assets/images/pin5.png';

const redIcon = new L.Icon({
    iconUrl: red,
    iconRetinaUrl: red,
    iconSize: new L.Point(25, 35),
    className: 'leaflet-div-icon',
});

const greenIcon = new L.Icon({
    iconUrl: green,
    iconRetinaUrl: green,
    iconSize: new L.Point(25, 35),
    className: 'leaflet-div-icon',
});

const blueIcon = new L.Icon({
    iconUrl: blue,
    iconRetinaUrl: blue,
    iconSize: new L.Point(25, 35),
    className: 'leaflet-div-icon',
});

const orangeIcon = new L.Icon({
    iconUrl: orange,
    iconRetinaUrl: orange,
    iconSize: new L.Point(25, 35),
    className: 'leaflet-div-icon',
});

const purpleIcon = new L.Icon({
    iconUrl: purple,
    iconRetinaUrl: purple,
    iconSize: new L.Point(25, 35),
    className: 'leaflet-div-icon',
});

export { redIcon, greenIcon, blueIcon, orangeIcon, purpleIcon };
