import {
    AppBar,
    Button,
    createStyles,
    CssBaseline,
    Drawer,
    Hidden,
    IconButton,
    makeStyles,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
} from '@material-ui/core';
import { List, ListItem, ListItemIcon, ListItemText, useTheme } from '@material-ui/core';
import { AuthStatus, ReduxState } from 'interface';
import { __AuthStatus } from 'interface/register';
import React, { useEffect, useRef } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { setAuthStatus, setUserData } from 'redux/actions';
import { authToken } from 'scripts/storage';
import './base.style.scss';
import { post, responseValidator } from '../../scripts/api';
import { API, RoutePath } from 'data';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    }),
);

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const Base: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector> & any) {
    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const menuRef = useRef<HTMLInputElement>(null);
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const handleClick = () => {
        setOpen(!open);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        post(API.logout, {}).then((res) => {
            if (responseValidator(res.status)) {
                authToken.remove();
                props.dispatch(setAuthStatus(AuthStatus.inValid));
                props.dispatch(setUserData(null));
            }
        });
        setAnchorEl(null);
    };

    useEffect(() => {
        const handler = (e: any) => {
            if (menuRef) {
                if (menuRef.current && !menuRef.current.contains(e.target)) {
                    setAnchorEl(null);
                }
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);
    const drawer = (
        <div className="base">
            <div className={classes.toolbar + ' ' + 'toolbar'}>
                <h3>وکالپ</h3>
            </div>
            <List>
                <NavLink to={RoutePath.locations} activeClassName="is_active">
                    <ListItem button>
                        <ListItemIcon>
                            <i className="material-icons">room</i>
                        </ListItemIcon>
                        <ListItemText primary="موقعیت های مکانی" />
                    </ListItem>
                </NavLink>
                <NavLink to={RoutePath.rules} activeClassName="is_active">
                    <ListItem button>
                        <ListItemIcon>
                            <i className="material-icons">gavel</i>
                        </ListItemIcon>
                        <ListItemText primary="قوانین و مقررات" />
                    </ListItem>
                </NavLink>
                <NavLink to={RoutePath.admins} activeClassName="is_active">
                    <ListItem button>
                        <ListItemIcon>
                            <i className="material-icons">add</i>
                        </ListItemIcon>
                        <ListItemText primary="ادمین " />
                    </ListItem>
                </NavLink>
                <NavLink to={RoutePath.advisory_opinion} activeClassName="is_active">
                    <ListItem button>
                        <ListItemIcon>
                            <i className="material-icons-outlined">psychology</i>
                        </ListItemIcon>
                        <ListItemText primary="نظرات مشاوره " />
                    </ListItem>
                </NavLink>
                <NavLink to={RoutePath.convention} activeClassName="is_active">
                    <ListItem button>
                        <ListItemIcon>
                            <i className="material-icons-outlined">add</i>
                        </ListItemIcon>
                        <ListItemText primary="قرار داد" />
                    </ListItem>
                </NavLink>
                <NavLink to={RoutePath.judicial_decision} activeClassName="is_active">
                    <ListItem button>
                        <ListItemIcon>
                            <i className="material-icons">gavel</i>
                        </ListItemIcon>
                        <ListItemText primary=" تصمیم قضایی" />
                    </ListItem>
                </NavLink>
                <NavLink to={RoutePath.legal_doctrine} activeClassName="is_active">
                    <ListItem button>
                        <ListItemIcon>
                            <i className="material-icons">gavel</i>
                        </ListItemIcon>
                        <ListItemText primary=" دکترین حقوقی" />
                    </ListItem>
                </NavLink>
            </List>
        </div>
    );
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <i className="material-icons bars">menu</i>
                    </IconButton>
                    <div className="app-bar-actions">
                        <div className="user">
                            <Button
                                className="user-menu"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClickMenu}
                            >
                                <span>{props.user?.phone}</span>
                                <i className="material-icons">expand_more</i>
                            </Button>
                            <div ref={menuRef}>
                                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}>
                                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                                </Menu>
                            </div>

                            <span className="divider"></span>
                            <i className="material-icons">notifications</i>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => {
    return {
        isAuth: state.authStatus,
        user: state.userData,
    };
};

const connector = connect(mapStateToProps);
export default connector(Base);
