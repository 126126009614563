import React, { useState } from 'react';
import { authToken } from 'scripts/storage';
import { generateOAuthHeader } from '../../scripts/api';
import Env from 'env.json';
import './login.style.scss';
import { Button, TextField } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import { ReduxState } from '../../interface';
import VekalappInit from '../vekalapp.script';
import { toast } from 'react-toastify';
import { API } from 'data';

const Login: React.FC<ConnectedProps<typeof connector>> = function (props: ConnectedProps<typeof connector>) {
    const [phone, setPhone] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);

    function onLoginHandler() {
        const data = new FormData();
        if (phone && password) {
            data.append('password', password);
            data.append('username', phone);
            data.append('grant_type', 'password');
        }
        fetch(API.new_auth, {
            method: 'POST',
            body: data,
            headers: generateOAuthHeader(),
        }).then((res) =>
            res.json().then((response) => {
                if (res.status == 200) {
                    authToken.set(response);
                    VekalappInit(props.dispatch).then();
                } else {
                    toast.error('خطایی رخ داده است');
                }
            }),
        );
    }

    return (
        <div className="admin-login-form">
            <div className="context">
                <p>ورود ادمین</p>
                <TextField
                    // type="number"
                    onChange={(e) => setPhone(e.target.value)}
                    label="شماره تلفن"
                    placeholder="شماره تلفن را وارد کنید"
                    variant="outlined"
                    className="item"
                    value={phone ? phone : undefined}
                />
                <TextField
                    className="item"
                    type="password"
                    value={password ? password : null}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="رمز عبور را وارد کنید"
                    label="رمز عبور"
                    variant="outlined"
                />
                <Button onClick={onLoginHandler} className="login" variant="contained" size="large">
                    ورود
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: ReduxState) => ({
    isAuth: state.authStatus,
});

const connector = connect(mapStateToProps);
export default connector(Login);
