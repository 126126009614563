import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    TextField,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-datepicker2';
import { AdvisoryOpinionCardProps } from './advisory_opinionCard.interface';
const AdvisoryOpinionCard: React.FC<AdvisoryOpinionCardProps> = (props: AdvisoryOpinionCardProps) => {
    const startCalender2 = useRef<any>();
    const [deleteModalStatus, setDeleteModalStatus] = React.useState(false);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [closeStartDate, setCloseStartDate] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({
        id: props.advisoryOpinionSingle.id,
        created_at: props.advisoryOpinionSingle.created_at,
        title: props.advisoryOpinionSingle.title,
        description: props.advisoryOpinionSingle.description,
        issue_date: props.advisoryOpinionSingle.issue_date,
        creator: props.advisoryOpinionSingle.creator,
        article: props.advisoryOpinionSingle.article,
    });

    const handleClickOpenDeleteModal = () => {
        setDeleteModalStatus(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalStatus(false);
    };

    const handleClickOpenEditModal = () => {
        setEditModalStatus(true);
    };

    const handleCloseEditModal = () => {
        setEditModalStatus(false);
    };

    const handleDeleteLocation = (id) => {
        setLoading(true);
        props.deleteAdvisoryOpinion(id).then(() => {
            setLoading(false);
            handleCloseDeleteModal();
        });
    };

    const handleEditLocation = () => {
        setLoading(true);
        if (props.advisoryOpinionSingle.id) {
            props.editAdvisoryOpinion(props.advisoryOpinionSingle.id, formData).then(() => {
                setLoading(false);
                handleCloseEditModal();
            });
        }
    };
    useEffect(() => {
        const handler = (e) => {
            if (!startCalender2.current?.contains(e.target)) {
                setCloseStartDate(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);
    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={editModalStatus}
                onClose={handleCloseEditModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">ویرایش دسته بندی</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>

                        <div className="col-md-6 " style={{ marginTop: '-8px' }}>
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-4 ">
                            <TextField
                                autoFocus
                                label="توضیحات"
                                type="color"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, article: e.target.value })}
                                multiline
                                rows={1}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="input-with-icon-adornment">از تاریخه </InputLabel>
                                <Input
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <span className="material-icons-outlined">keyboard_arrow_down</span>
                                        </InputAdornment>
                                    }
                                    placeholder="بازه شروع را انتخاب کنید"
                                    onClick={() => setCloseStartDate(true)}
                                    disabled={true}
                                    value={formData.issue_date && formData.issue_date.toLocaleDateString('fa')}
                                />
                                {closeStartDate && (
                                    <div ref={startCalender2} className="filter-days">
                                        <Calendar
                                            value={formData.issue_date}
                                            isGregorian={false}
                                            onChange={(value) => {
                                                setFormData({ ...formData, issue_date: value._d });
                                                setCloseStartDate(false);
                                            }}
                                        />
                                    </div>
                                )}
                            </FormControl>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleEditLocation} color="primary" variant="contained">
                        ویرایش
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={deleteModalStatus}
                keepMounted
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">حذف دسته بندی</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        آیا از حذف این دسته بندی اطمینان دارید ؟
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCloseDeleteModal} color="primary" variant="contained">
                        انصراف
                    </Button>
                    <Button
                        onClick={() => handleDeleteLocation(props.advisoryOpinionSingle.id)}
                        disabled={loading}
                        color="primary"
                        variant="outlined"
                    >
                        حذف
                    </Button>
                </DialogActions>
            </Dialog>
            <tr>
                <td>{props.advisoryOpinionSingle.title}</td>
                <td>{props.advisoryOpinionSingle.description}</td>

                <td>
                    <i className="material-icons-outlined" onClick={handleClickOpenDeleteModal}>
                        delete
                    </i>
                    <i className="material-icons-outlined" onClick={handleClickOpenEditModal}>
                        edit
                    </i>
                </td>
            </tr>
        </>
    );
};

export default AdvisoryOpinionCard;
