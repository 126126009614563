type routeParam = string | number;
export const __RoutePath = {
    locations: '/locations',
    rules: '/rules',
    rule: (ruleID = ':rule') => `/rules/${ruleID}`,
    login: '/login/',
    auth: '/authorization/',
    admins: '/admins/',
    advisory_opinion: '/advisory_opinion/',
    advisory_opinion_by: (advisory_opinion_id: ':advisory_opinion') => `/radvisory_opinion/${advisory_opinion_id}`,
    convention: '/convention/',
    judicial_decision: '/judicial_decision/',
    legal_doctrine: '/legal_doctrine/',
};
