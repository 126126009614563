import { AuthStatus, ReduxAction, UserData } from 'interface';

const AUTH_STATUS = 'AUTH_STATUS';
const setAuthStatus = (payload: AuthStatus): ReduxAction<AuthStatus> => ({
    type: AUTH_STATUS,
    payload: payload,
});
const USER_DATA = 'USER_DATA';
const setUserData = (payload: any): ReduxAction<UserData> => ({
    type: USER_DATA,
    payload: payload,
});
export { AUTH_STATUS, setAuthStatus, USER_DATA, setUserData };
