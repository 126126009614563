import {
    Button,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { Pagination as PG } from 'interface';
import { LocationType } from 'interface/vekalapp';
import React, { useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { del, get, patch, post, responseValidator } from 'scripts/api';
import LocationRow from './locationRow/locationRow.index';
import { redIcon } from './locations.icon';
import { Bounds, __Citiies, __Province } from './locations.interface';
import './locations.style.scss';
import Skeleton from '@material-ui/lab/Skeleton';
import { locationType } from './locations.data';
import { API, RoutePath } from 'data';
const Locations: React.FC = () => {
    const tehranCenter = {
        lat: 35.6892,
        lng: 51.389,
    };
    const tehranBounds: Bounds = {
        north: 51.451807022094734,
        south: 51.32615089416504,
        west: 35.687487524875394,
        east: 35.690903370337175,
    };

    const history = useHistory();

    const [toggle, setToggle] = useState(true);
    const [position, setPosition] = useState(tehranCenter);
    const [currentPosition, setCurrentPosition] = useState<any>(undefined);
    const [locationsOnMap, setLocationsOnMap] = useState<LocationType[] | undefined>(undefined);
    const [locations, setLocations] = useState<LocationType[] | undefined>(undefined);
    const [numOfPages, setNumOfpages] = useState<number | undefined>(undefined);
    const [zoom, setZoom] = useState<number>(14);
    const [locationEnum, setLocationEnum] = useState<number | undefined>(undefined);
    const [bounds, setBounds] = useState<Bounds>(tehranBounds);
    const [open, setOpen] = React.useState(false);
    const [markerStatus, setMarkerStatus] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({
        lat: position.lat,
        lng: position.lng,
        location_type: '',
        name: '',
        city: '',
    });
    const [city, setCity] = useState<__Citiies[] | undefined>();
    const [province, setProvince] = useState<__Province[] | undefined>();
    const handleClickOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    // get locations
    useEffect(() => {
        const width = bounds.east - bounds.west;
        const height = bounds.north - bounds.south;
        get<PG<LocationType>>(API.locations, {
            // priority: Math.round(zoom / 3),
            lng_range_max: bounds.north + height,
            lng_range_min: bounds.south - height,
            lat_range_max: bounds.east + width,
            lat_range_min: bounds.west - width,
        }).then((response) => {
            if (responseValidator(response.status)) {
                setLocationsOnMap(response.data?.data);
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
        get<PG<__Province>>(API.province, { page: 1, page_size: 1000 }).then((response) => {
            if (responseValidator(response.status)) {
                setProvince(response.data?.data);
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    }, [bounds, zoom]);
    function getLocations() {
        const params = new URLSearchParams(window.location.search);
        const temp: any = params.get('page');
        const forms = { page: temp ? temp : 1, page_size: 9 };
        locationEnum && Object.assign(forms, { location_type: locationEnum });
        get<PG<LocationType>>(API.locations, forms).then((response) => {
            if (responseValidator(response.status)) {
                setLocations(response.data?.data);
                setNumOfpages(response.data?.num_of_pages);
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    }
    useEffect(() => {
        getLocations();
        get<PG<__Province>>(API.province, { page: 1, page_size: 1000 }).then((response) => {
            if (responseValidator(response.status)) {
                setProvince(response.data?.data);
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    }, [history.location, locationEnum]);

    // center map to user current location
    function ChangeMapView() {
        const map = useMap();
        if (!currentPosition) {
            map.locate();
        }
        const events = useMapEvents({
            locationfound: (e) => {
                setCurrentPosition(e.latlng);
                map.flyTo(e.latlng, map.getZoom());
            },
            move: (e) => {
                const { lat, lng } = e.target.getCenter();
                const width = bounds.east - bounds.west;
                const height = bounds.north - bounds.south;
                if (
                    lat < position.lat - width ||
                    lat > position.lat + width ||
                    lng < position.lng - height ||
                    lng > position.lng + height
                ) {
                    // setPosition({ lng, lat });
                    setBounds({
                        north: map.getBounds().getNorthEast().lng,
                        south: map.getBounds().getSouthWest().lng,
                        east: map.getBounds().getNorthEast().lat,
                        west: map.getBounds().getSouthWest().lat,
                    });
                }
            },
            zoomend: (e) => {
                setBounds({
                    north: map.getBounds().getNorthEast().lng,
                    south: map.getBounds().getSouthWest().lng,
                    east: map.getBounds().getNorthEast().lat,
                    west: map.getBounds().getSouthWest().lat,
                });
                setZoom(e.target.getZoom());
            },
        });
        return null;
    }

    function ChangeSmallMapView() {
        const map = useMap();
        const events = useMapEvents({
            dragend: (e) => {
                const { lat, lng } = e.target.getCenter();
                setFormData({ ...formData, lat, lng });
                setPosition({ lng, lat });
                setMarkerStatus(false);
            },
            dragstart: () => {
                setMarkerStatus(true);
            },
        });
        map.setView(position, map.getZoom());
        return null;
    }

    const handleToggle = function () {
        setToggle(!toggle);
    };

    const handleDeleteLocation = (id) => {
        return new Promise((resolve) => {
            del(API.locations + `${id}`, {
                id,
            })
                .then((response) => {
                    if (responseValidator(response.status)) {
                        if (locations) setLocations(locations?.filter((location) => location.id != id));
                        toast.success('موقعیت مکانی حذف شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleEditLocation = (id, updatedData) => {
        return new Promise((resolve) => {
            patch<LocationType>(API.locations + `${id}/`, {
                id,
                ...updatedData,
            })
                .then((response) => {
                    if (responseValidator(response.status) && response.data) {
                        // const index = locations?.findIndex((location) => location.id === id);
                        // const newLocations = locations;
                        // if (newLocations && index != undefined) {
                        //     newLocations[index] = {
                        //         ...response.data,
                        //     };
                        //     setLocations([...newLocations]);
                        // }
                        getLocations();
                        toast.success('موقعیت مکانی ویرایش شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleCreateLocation = () => {
        setLoading(true);
        post<LocationType>(API.locations, {
            name: formData.name,
            location_type: formData.location_type,
            lat: formData.lat,
            lng: formData.lng,
            city: parseInt(formData.city),
            province: parseInt(formData.province),
            // province: 'test',
        })
            .then((response) => {
                if (responseValidator(response.status) && response.data) {
                    setLocations(locations?.concat(response.data));
                    handleCloseModal();
                    toast.success('موقعیت مکانی اضافه شد.');
                } else {
                    toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleCity = (id) => {
        get<PG<__Citiies>>(API.city, { page: 1, page_size: 1000, province: id }).then((response) => {
            if (responseValidator(response.status)) {
                setCity(response.data?.data);
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    };
    return (
        <div className="vekalapp-admin-locations-page">
            <Dialog open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">موقعیت مکانی</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            />
                        </div>
                        <div className="col-md-6 loc_types">
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">نوع</InputLabel>
                                <Select labelId="demo-simple-select-label" id="demo-simple-select">
                                    {locationType.map((location, index) => (
                                        <MenuItem
                                            key={index}
                                            onClick={(e) =>
                                                setFormData({ ...formData, location_type: location.payload })
                                            }
                                            value={location.payload}
                                        >
                                            {location.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 loc_types mt-3">
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">استان</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        handleCity(e.target.value);
                                    }}
                                    onClick={(e: any) => setFormData({ ...formData, province: e.target.value })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                >
                                    {province?.map((location, index) => (
                                        <MenuItem key={index} value={location.id}>
                                            {location.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 loc_types mt-3">
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">شهر</InputLabel>
                                <Select
                                    onClick={(e: any) => setFormData({ ...formData, city: e.target.value })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                >
                                    {city?.map((location, index) => (
                                        <MenuItem key={index} value={location.id}>
                                            {location.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 mt-4 add-location-map">
                            <MapContainer style={{ height: '250px' }} center={tehranCenter} zoom={14}>
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <span className={`material-icons map-marker ${markerStatus && 'marker-dragging'}`}>
                                    place
                                </span>
                                <ChangeSmallMapView />
                            </MapContainer>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCreateLocation} color="primary" variant="contained">
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="header">
                <div className="title">
                    <h3>موقعیت مکانی</h3>
                    <div className="icons">
                        <i
                            onClick={handleToggle}
                            className="material-icons"
                            style={{ color: toggle ? '#3b86ff' : '#a5a4bf' }}
                        >
                            list
                        </i>
                        <div className="divider"></div>
                        <i
                            onClick={handleToggle}
                            className="material-icons"
                            style={{ color: !toggle ? '#3b86ff' : '#a5a4bf' }}
                        >
                            map
                        </i>
                    </div>
                </div>
                <div className="filter">
                    <FormControl>
                        <InputLabel htmlFor="input-with-icon-adornment">جستجو</InputLabel>
                        <Input
                            id="input-with-icon-adornment"
                            startAdornment={
                                <InputAdornment position="start">
                                    <i className="material-icons">search</i>
                                </InputAdornment>
                            }
                            placeholder="جستجو کنید"
                        />
                    </FormControl>
                    <FormControl>
                        <InputLabel id="demo-simple-select-label">دسته بندی</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select">
                            {locationType.map((location, index) => (
                                <MenuItem
                                    key={index}
                                    onClick={() => setLocationEnum(location.payload)}
                                    value={location.payload}
                                >
                                    {location.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button color="secondary" onClick={handleClickOpenModal}>
                        افزودن +
                    </Button>
                </div>
            </div>
            {toggle ? (
                <div className="content">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>نام</th>
                                <th>شهر</th>
                                <th>استان</th>
                                <th>نوع</th>
                                <th>عملیات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {locations ? (
                                locations.length ? (
                                    locations.map((location) => (
                                        <LocationRow
                                            key={location.id}
                                            location={location}
                                            deleteLocation={handleDeleteLocation}
                                            editLocation={handleEditLocation}
                                            province={province}
                                        />
                                    ))
                                ) : (
                                    'no result'
                                )
                            ) : (
                                <>
                                    {Array.from(Array(2).keys()).map((item, i) => (
                                        <tr key={i}>
                                            {Array.from(Array(5).keys()).map((item, i) => (
                                                <td key={i}>
                                                    <Skeleton height={50} />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>
                    {numOfPages && (
                        <div className="d-flex justify-content-center pagination">
                            {numOfPages > 1 && (
                                <Pagination
                                    color="secondary"
                                    count={numOfPages}
                                    onChange={(e, page) => {
                                        setLocations(undefined);
                                        history.push(RoutePath.locations + `?page=${page}`);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div className="map">
                    <MapContainer center={tehranCenter} zoom={14}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <ChangeMapView />
                        {locationsOnMap
                            ? locationsOnMap.map((marker, i) => (
                                  <Marker key={i} position={[marker.lat, marker.lng]} icon={redIcon}>
                                      <Popup>{marker.name}</Popup>
                                  </Marker>
                              ))
                            : null}
                        {currentPosition && (
                            <Marker position={currentPosition} icon={redIcon}>
                                <Popup>شما</Popup>
                            </Marker>
                        )}
                    </MapContainer>
                </div>
            )}
        </div>
    );
};

export default Locations;
