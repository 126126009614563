import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import { RoutePath } from 'data';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RuleCardProps } from './ruleCard.interface';
import './ruleCard.style.scss';

const RuleCard: React.FC<RuleCardProps> = (props: RuleCardProps) => {
    const [deleteModalStatus, setDeleteModalStatus] = React.useState(false);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = useState<any>({
        title: props.rule.title,
        description: props.rule.description,
    });
    const handleClickOpenDeleteModal = () => {
        setDeleteModalStatus(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalStatus(false);
    };

    const handleClickOpenEditModal = (e) => {
        setEditModalStatus(true);
    };

    const handleCloseEditModal = () => {
        setEditModalStatus(false);
    };

    const handleDeleteLocation = (id) => {
        setLoading(true);
        props.deleteRule(id).then(() => {
            setLoading(false);
            handleCloseDeleteModal();
        });
    };

    const handleEditLocation = () => {
        setLoading(true);
        props.editRule(props.rule.id, formData).then(() => {
            setLoading(false);
            handleCloseEditModal();
        });
    };
    return (
        <>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={editModalStatus}
                onClose={handleCloseEditModal}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">ویرایش قانون</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ title: e.target.value })}
                                value={formData.title}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <TextField
                                label="توضیحات"
                                fullWidth
                                multiline
                                rows={2}
                                value={formData.description}
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleEditLocation} color="primary" variant="contained">
                        ویرایش
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteModalStatus}
                keepMounted
                fullWidth
                maxWidth="sm"
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">حذف قانون</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        آیا از حذف این قانون اطمینان دارید ؟
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCloseDeleteModal} color="primary" variant="contained">
                        انصراف
                    </Button>
                    <Button
                        onClick={() => handleDeleteLocation(props.rule.id)}
                        disabled={loading}
                        color="primary"
                        variant="outlined"
                    >
                        حذف
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="ruleCard">
                <Link to={RoutePath.rule(props.rule.id.toString())}>
                    <h3>{props.rule.title}</h3>
                    <p>{props.rule.description}</p>
                </Link>
                <div className="actions">
                    <i className="material-icons" onClick={handleClickOpenDeleteModal}>
                        delete
                    </i>
                    <i className="material-icons" onClick={handleClickOpenEditModal}>
                        edit
                    </i>
                </div>
            </div>
        </>
    );
};

export default RuleCard;
