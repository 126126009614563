import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from 'redux/store';
import Vekalapp from './vekalapp/vekalapp.index';
import 'utilities/styles/index.scss';
function App() {
    return (
        <Provider store={store}>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Vekalapp />
        </Provider>
    );
}

export default App;
