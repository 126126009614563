import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import { Pagination as PG } from 'interface';
import momentJalaali from 'moment-jalaali';
import React, { useEffect, useRef, useState } from 'react';
import { Calendar } from 'react-datepicker2';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { del, get, patch, post, responseValidator } from 'scripts/api';
import { JudicialDecisionInterFace } from './judicial_decision.interface';
import './judicial_decision.style.scss';
import { JudicalType } from './judicial_decision.data';
import JudicialDecisionCard from './judicial_decisionCard/judicial_decisionCard.index';
import { API, RoutePath } from 'data';
const JudicialDecisions: React.FC = () => {
    const DateCalender = useRef<any>();
    const history = useHistory();
    const [numOfPages, setNumOfpages] = useState<number | undefined>(undefined);

    const [startTime, setstartTime] = useState(momentJalaali());
    const [loading, setLoading] = useState<boolean>(false);
    const [judicialDecision, setJudicialDecision] = useState<JudicialDecisionInterFace[] | undefined>();
    const [open, setOpen] = useState(false);
    const [closeStartDate, setCloseStartDate] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>();
    const [inputDate, setInputDate] = useState<any>();
    const handleClickOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const temp: any = params.get('page');
        get<PG<JudicialDecisionInterFace>>(API.judicial_decision, {
            page: temp ? temp : 1,
            page_size: 12,
        }).then((response) => {
            if (responseValidator(response.status)) {
                setNumOfpages(response.data?.num_of_pages);
                setJudicialDecision(response.data?.data);
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    }, [history.location]);

    const handleDeleteJudicialDecision = (id) => {
        return new Promise((resolve) => {
            del(API.judicial_decision + `${id}`, {
                id,
            })
                .then((response) => {
                    if (responseValidator(response.status)) {
                        if (judicialDecision) setJudicialDecision(judicialDecision?.filter((c) => c.id != id));
                        toast.success('دسته بندی حذف شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleEditJudicialDecision = (id, updatedData) => {
        return new Promise((resolve) => {
            patch<JudicialDecisionInterFace>(API.judicial_decision + `${id}/`, {
                id,
                ...updatedData,
            })
                .then((response) => {
                    if (responseValidator(response.status) && response.data) {
                        const index = judicialDecision?.findIndex((c) => c.id === id);
                        const newJudicialDecision = judicialDecision;
                        if (newJudicialDecision && index != undefined) {
                            newJudicialDecision[index] = {
                                ...response.data,
                            };
                            setJudicialDecision([...newJudicialDecision]);
                        }
                        toast.success('دسته بندی ویرایش شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleCreateLocation = () => {
        setLoading(true);
        post<JudicialDecisionInterFace>(API.advisory_opinion, {
            ...formData,
        })
            .then((response) => {
                if (responseValidator(response.status) && response.data) {
                    setJudicialDecision(judicialDecision?.concat(response.data));
                    handleCloseModal();
                    toast.success('دسته بندی اضافه شد.');
                } else {
                    toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        const handler = (e) => {
            if (!DateCalender.current?.contains(e.target)) {
                setCloseStartDate(false);
            }
        };
        document.addEventListener('mousedown', handler);
        return () => {
            document.removeEventListener('mousedown', handler);
        };
    }, []);
    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">افزودن دسته بندی</DialogTitle>
                <DialogContent>
                    <div className="row" style={closeStartDate ? { height: '570px' } : { height: 'unset' }}>
                        <div className="col-6 ">
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="input-with-icon-adornment"> تاریخ </InputLabel>
                                <Input
                                    id="input-with-icon-adornment"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <span className="material-icons-outlined">keyboard_arrow_down</span>
                                        </InputAdornment>
                                    }
                                    placeholder="تاریخ را انتخاب کنید"
                                    onClick={() => setCloseStartDate(true)}
                                    disabled={true}
                                    value={inputDate}
                                />
                                {closeStartDate && (
                                    <div ref={DateCalender} className="filter-days">
                                        <Calendar
                                            value={startTime}
                                            isGregorian={false}
                                            onChange={(value) => {
                                                setstartTime(value);
                                                setInputDate(value._d.toLocaleDateString('fa'));
                                                setFormData({ ...formData, issue_date: value._d.toISOString() });
                                                setCloseStartDate(false);
                                            }}
                                        />
                                    </div>
                                )}
                            </FormControl>
                        </div>
                        <div className="col-md-6 loc_types">
                            {' '}
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">نوع</InputLabel>
                                <Select
                                    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                >
                                    {JudicalType.map((type, index) => (
                                        <MenuItem key={index} value={type.payload}>
                                            {type.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>{' '}
                        </div>

                        <div className="col-md-6 mt-4">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>

                        <div className="col-md-6 mt-4">
                            <TextField
                                autoFocus
                                label="شماره شعبه"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>

                        <div className="col-md-6 mt-4">
                            <TextField
                                autoFocus
                                label="موضوع"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-6 mt-4 ">
                            <TextField
                                autoFocus
                                label="توضیحات"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, article: e.target.value })}
                                multiline
                                rows={1}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCreateLocation} color="primary" variant="contained">
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="vekalapp-admin-judicialDecisions-page">
                <div className="header">
                    <div className="title">
                        <h3>نظرات مشاوره</h3>
                    </div>
                    <div className="filter">
                        <FormControl>
                            <InputLabel htmlFor="input-with-icon-adornment">جستجو</InputLabel>
                            <Input
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <i className="material-icons">search</i>
                                    </InputAdornment>
                                }
                                placeholder="جستجو کنید"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">دسته بندی</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select">
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <Button color="secondary" onClick={handleClickOpenModal}>
                            افزودن +
                        </Button>
                    </div>
                </div>
                <div className="content">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>نام</th>
                                <th>توضیح</th>
                                <th>نوع</th>
                                <th>عملیات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {judicialDecision ? (
                                judicialDecision.length ? (
                                    judicialDecision.map((judicialDecisionSingle) => (
                                        <JudicialDecisionCard
                                            judicialDecisionSingle={judicialDecisionSingle}
                                            deleteJudicialDecision={handleDeleteJudicialDecision}
                                            editJudicialDecision={handleEditJudicialDecision}
                                            key={judicialDecisionSingle.id}
                                            judicialDecision={judicialDecision}
                                        />
                                    ))
                                ) : (
                                    'no result'
                                )
                            ) : (
                                <>
                                    {Array.from(Array(2).keys()).map((item, i) => (
                                        <tr key={i}>
                                            {Array.from(Array(5).keys()).map((item, i) => (
                                                <td key={i}>
                                                    <Skeleton height={50} />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>

                    {numOfPages && (
                        <div className="d-flex justify-content-center mt-4">
                            {numOfPages > 1 && (
                                <Pagination
                                    color="secondary"
                                    count={numOfPages}
                                    onChange={(e, page) => {
                                        setJudicialDecision(undefined);
                                        history.push(RoutePath.judicial_decision + `?page=${page}`);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default JudicialDecisions;
