import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import { API } from 'data';
import { ArticleType, Pagination as PaginationType } from 'interface';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { del, get, patch, post, responseValidator } from 'scripts/api';
import ArticleCard from '../articleCard/articleCard.index';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const editorConfiguration = {
    toolbar: {
        items: [
            'heading',
            '|',
            'fontsize',
            '|',
            'alignment',
            '|',
            'bold',
            'italic',
            'strikethrough',
            'underline',
            'superscript',
            '|',
            'link',
            '|',
            'outdent',
            'indent',
            '|',
            'bulletedList',
            'numberedList',
            'todoList',
            '|',
            'code',
            'codeBlock',
            '|',
            'blockQuote',
            '|',
            'undo',
            'redo',
        ],
        shouldNotGroupWhenFull: true,
    },
};

const ArticleList: React.FC<{ onClose: () => void; create: boolean }> = (props: {
    onClose: () => void;
    create: boolean;
}) => {
    const categoryPageSize = 12;
    const { rule } = useParams<any>();
    const history = useHistory();
    const [group, setGroup] = useState<string | null>(new URLSearchParams(window.location.search).get('group'));
    const [articles, setArticles] = useState<PaginationType<ArticleType>>();
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>();

    useEffect(() => {
        setGroup(new URLSearchParams(window.location.search).get('group'));
        setPage(1);
    }, [history.location.search]);

    useEffect(() => {
        setArticles(undefined);
    }, [page, group]);

    useEffect(() => {
        if (!articles) {
            get<PaginationType<ArticleType>>(API.articles, {
                page,
                page_size: categoryPageSize,
                group: group && group.length ? group : '-1',
                rule,
            }).then((response) => {
                if (responseValidator(response.status) && response.data) {
                    setArticles(response.data);
                } else {
                    toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
                }
            });
        }
    }, [articles]);

    const handleDeleteRule = (id) => {
        return new Promise((resolve) => {
            del(API.articles + `${id}`, {
                id,
            })
                .then((response) => {
                    if (responseValidator(response.status)) {
                        setArticles(undefined);
                        toast.success('ماده حذف شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleEditRule = (id, updatedData) => {
        return new Promise((resolve) => {
            patch<ArticleType>(API.articles + `${id}/`, {
                id,
                ...updatedData,
            })
                .then((response) => {
                    if (responseValidator(response.status) && response.data) {
                        const index = articles?.data?.findIndex((rule) => rule.id === id);
                        const newRules = articles ? { ...articles } : undefined;
                        if (newRules && index != undefined) {
                            newRules.data[index] = response.data;
                            setArticles(newRules);
                        }
                        toast.success('ماده ویرایش شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleCreateLocation = () => {
        setLoading(true);
        post<PaginationType<ArticleType>>(API.articles, {
            title: formData.title,
            rule,
            group: group ? group : undefined,
            description: formData.description,
        }).then((response) => {
            setLoading(false);
            if (responseValidator(response.status) && response.data) {
                setArticles(undefined);
                props.onClose();
                toast.success('ماده اضافه شد.');
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    };

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={props.create}
                onClose={props.onClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">افزودن قانون</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-4">
                            <div className="MyEditor">
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={editorConfiguration}
                                    data={formData && formData.description}
                                    onReady={(editor) => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setFormData({ ...formData, description: data });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCreateLocation} color="primary" variant="contained">
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="content">
                <div className="articles">
                    {articles ? (
                        articles.data.length ? (
                            articles.data.map((article) => (
                                <ArticleCard
                                    article={article}
                                    deleteArticle={handleDeleteRule}
                                    editArticle={handleEditRule}
                                    key={article.id}
                                />
                            ))
                        ) : (
                            <p style={{ padding: '20px', display: 'none' }}>no item</p>
                        )
                    ) : (
                        <>
                            {Array.from(Array(2).keys()).map((item, i) => (
                                <div className="w-100 my-2" key={i}>
                                    <Skeleton width={'100%'} height={130} />
                                </div>
                            ))}
                        </>
                    )}
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                    <div className="grid"></div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    {articles?.num_of_pages && articles.num_of_pages > 1 && (
                        <Pagination
                            color="secondary"
                            count={articles?.num_of_pages}
                            page={page}
                            onChange={(e, page) => {
                                setPage(page);
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ArticleList;
