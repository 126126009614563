import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import { Pagination as PG } from 'interface';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { del, get, patch, post, responseValidator } from 'scripts/api';
import LegalDoctrineCard from './legal_doctrineCard/legal_doctrineCard.index';
import { LegalDoctrineInterface } from './legal_doctrine.interface';
import './legal_doctrine.style.scss';
import { API, RoutePath } from 'data';
const LegalDoctrines: React.FC = () => {
    const history = useHistory();
    const [numOfPages, setNumOfpages] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [legalDoctrine, setLegalDoctrine] = useState<LegalDoctrineInterface[] | undefined>();
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState<any>();
    const handleClickOpenModal = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
        setOpen(false);
    };
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const temp: any = params.get('page');
        get<PG<LegalDoctrineInterface>>(API.legal_doctrine, {
            page: temp ? temp : 1,
            page_size: 12,
        }).then((response) => {
            if (responseValidator(response.status)) {
                setNumOfpages(response.data?.num_of_pages);
                setLegalDoctrine(response.data?.data);
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    }, [history.location]);

    const handleDeleteLegalDoctrine = (id) => {
        return new Promise((resolve) => {
            del(API.legal_doctrine + `${id}`, {
                id,
            })
                .then((response) => {
                    if (responseValidator(response.status)) {
                        if (legalDoctrine) setLegalDoctrine(legalDoctrine?.filter((c) => c.id != id));
                        toast.success('دسته بندی حذف شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleEditLegalDoctrine = (id, updatedData) => {
        return new Promise((resolve) => {
            patch<LegalDoctrineInterface>(API.legal_doctrine + `${id}/`, {
                id,
                ...updatedData,
            })
                .then((response) => {
                    if (responseValidator(response.status) && response.data) {
                        const index = legalDoctrine?.findIndex((c) => c.id === id);
                        const newLegalDoctrine = legalDoctrine;
                        if (newLegalDoctrine && index != undefined) {
                            newLegalDoctrine[index] = {
                                ...response.data,
                            };
                            setLegalDoctrine([...newLegalDoctrine]);
                        }
                        toast.success('دسته بندی ویرایش شد.');
                    } else {
                        toast.error('مشکلی در ارتباط پیش آمده است.');
                    }
                })
                .finally(() => {
                    resolve(true);
                });
        });
    };

    const handleCreateLocation = () => {
        setLoading(true);
        post<LegalDoctrineInterface>(API.legal_doctrine, {
            ...formData,
        })
            .then((response) => {
                if (responseValidator(response.status) && response.data) {
                    setLegalDoctrine(legalDoctrine?.concat(response.data));
                    handleCloseModal();
                    toast.success('دسته بندی اضافه شد.');
                } else {
                    toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Dialog maxWidth="sm" fullWidth open={open} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">افزودن دسته بندی</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12 ">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                            />
                        </div>
                        <div className="col-12 mt-4 ">
                            <TextField
                                autoFocus
                                label="توضیحات"
                                type="color"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                multiline
                                rows={1}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCreateLocation} color="primary" variant="contained">
                        افزودن
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="vekalapp-admin-LegalDoctrines-page">
                <div className="header">
                    <div className="title">
                        <h3>نظرات مشاوره</h3>
                    </div>
                    <div className="filter">
                        <FormControl>
                            <InputLabel htmlFor="input-with-icon-adornment">جستجو</InputLabel>
                            <Input
                                id="input-with-icon-adornment"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <i className="material-icons">search</i>
                                    </InputAdornment>
                                }
                                placeholder="جستجو کنید"
                            />
                        </FormControl>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">دسته بندی</InputLabel>
                            <Select labelId="demo-simple-select-label" id="demo-simple-select">
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select>
                        </FormControl>
                        <Button color="secondary" onClick={handleClickOpenModal}>
                            افزودن +
                        </Button>
                    </div>
                </div>
                <div className="content">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>نام</th>
                                <th>توضیح</th>

                                <th>عملیات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {legalDoctrine ? (
                                legalDoctrine.length ? (
                                    legalDoctrine.map((legalDoctrineSingle) => (
                                        <LegalDoctrineCard
                                            legalDoctrineSingle={legalDoctrineSingle}
                                            deletelegalDoctrine={handleDeleteLegalDoctrine}
                                            editlegalDoctrine={handleEditLegalDoctrine}
                                            key={legalDoctrineSingle.id}
                                            legalDoctrine={legalDoctrine}
                                        />
                                    ))
                                ) : (
                                    'no result'
                                )
                            ) : (
                                <>
                                    {Array.from(Array(2).keys()).map((item, i) => (
                                        <tr key={i}>
                                            {Array.from(Array(5).keys()).map((item, i) => (
                                                <td key={i}>
                                                    <Skeleton height={50} />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </>
                            )}
                        </tbody>
                    </table>

                    {numOfPages && (
                        <div className="d-flex justify-content-center mt-4">
                            {numOfPages > 1 && (
                                <Pagination
                                    color="secondary"
                                    count={numOfPages}
                                    onChange={(e, page) => {
                                        setLegalDoctrine(undefined);
                                        history.push(RoutePath.legal_doctrine + `?page=${page}`);
                                    }}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default LegalDoctrines;
