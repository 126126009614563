import {
    Button,
    DialogContentText,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { LocationType } from 'interface/vekalapp';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, useMap, useMapEvents } from 'react-leaflet';
import { responseValidator, get } from 'scripts/api';
import { LocationRowProps } from './locationRow.interface';
import { locationType } from '../locations.data';
import { Pagination as PG } from 'interface';
import { __Citiies, __Province } from '../locations.interface';
import { toast } from 'react-toastify';
import { API } from 'data';
const LocationRow: React.FC<LocationRowProps> = (props: LocationRowProps) => {
    const [deleteModalStatus, setDeleteModalStatus] = React.useState(false);
    const [editModalStatus, setEditModalStatus] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [markerStatus, setMarkerStatus] = useState<boolean>(false);
    const [city, setCity] = useState<__Citiies[] | undefined>();
    const [position, setPosition] = useState({ lat: props.location.lat, lng: props.location.lng });
    const [formData, setFormData] = useState<any>({
        lat: position.lat,
        lng: position.lng,
        location_type: props.location.location_type,
        name: props.location.name,
        city: props.location.city.id,
        province: props.location.province.id,
    });

    const handleClickOpenDeleteModal = () => {
        setDeleteModalStatus(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalStatus(false);
    };

    const handleClickOpenEditModal = () => {
        setEditModalStatus(true);
    };

    const handleCloseEditModal = () => {
        setEditModalStatus(false);
    };

    const handleDeleteLocation = (id) => {
        setLoading(true);
        props.deleteLocation(id).then(() => {
            setLoading(false);
            handleCloseDeleteModal();
        });
    };

    const handleEditLocation = () => {
        setLoading(true);
        props.editLocation(props.location.id, formData).then(() => {
            setLoading(false);
            handleCloseEditModal();
        });
    };

    function ChangeSmallMapView() {
        const map = useMap();
        const events = useMapEvents({
            dragend: (e) => {
                const { lat, lng } = e.target.getCenter();
                setFormData({ ...formData, lat, lng });
                setPosition({ lng, lat });
                setMarkerStatus(false);
            },
            dragstart: () => {
                setMarkerStatus(true);
            },
        });
        map.setView(position, map.getZoom());
        return null;
    }
    const handleCity = (id) => {
        get<PG<__Citiies>>(API.city, { page: 1, page_size: 1000, province: id }).then((response) => {
            if (responseValidator(response.status)) {
                setCity(response.data?.data);
            } else {
                toast.error('خطایی به وجود آمده است لطفا دوباره امتحان کنید.');
            }
        });
    };
    useEffect(() => {
        if (props.location.province && editModalStatus) handleCity(props.location.province.id);
    }, [props.location, editModalStatus]);
    return (
        <>
            <Dialog open={editModalStatus} onClose={handleCloseEditModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">ویرایش موقعیت مکانی</DialogTitle>
                <DialogContent>
                    <div className="row">
                        <div className="col-md-6">
                            <TextField
                                autoFocus
                                label="عنوان"
                                fullWidth
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                value={formData.name}
                            />
                        </div>
                        <div className="col-md-6 loc_types">
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">نوع</InputLabel>
                                <Select
                                    defaultValue={props.location.location_type}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    onChange={(e) => setFormData({ ...formData, location_type: e.target.value })}
                                >
                                    {locationType.map((location, index) => (
                                        <MenuItem key={index} value={location.payload}>
                                            {location.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 loc_types mt-3">
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">استان</InputLabel>
                                <Select
                                    onChange={(e) => {
                                        handleCity(e.target.value);
                                        setFormData({ ...formData, province: e.target.value });
                                    }}
                                    defaultValue={props.location.province.id}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                >
                                    {props.province?.map((location, index) => (
                                        <MenuItem key={index} value={location.id}>
                                            {location.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-md-6 loc_types mt-3">
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">شهر</InputLabel>
                                <Select
                                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    defaultValue={props.location.city.id}
                                >
                                    {city?.map((location, index) => (
                                        <MenuItem key={index} value={location.id}>
                                            {location.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-12 mt-4 add-location-map">
                            <MapContainer
                                style={{ height: '250px' }}
                                center={{ lat: props.location.lat, lng: props.location.lng }}
                                zoom={14}
                            >
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <span className={`material-icons map-marker ${markerStatus && 'marker-dragging'}`}>
                                    place
                                </span>
                                <ChangeSmallMapView />
                            </MapContainer>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleEditLocation} color="primary" variant="contained">
                        ویرایش
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={deleteModalStatus}
                keepMounted
                onClose={handleCloseDeleteModal}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">حذف موقعیت مکانی</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        آیا از حذف این موقعیت مکانی اطمینان دارید ؟
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleCloseDeleteModal} color="secondary" variant="contained">
                        انصراف
                    </Button>
                    <Button
                        onClick={() => handleDeleteLocation(props.location.id)}
                        disabled={loading}
                        color="secondary"
                        variant="outlined"
                    >
                        حذف
                    </Button>
                </DialogActions>
            </Dialog>
            <tr>
                <td>{props.location.name}</td>
                <td>{props.location.city.name}</td>
                <td>{props.location.province.name}</td>
                <td>
                    {' '}
                    {props.location.location_type && (
                        <>{locationType.find((item: any) => item.payload === props.location.location_type).title}</>
                    )}{' '}
                </td>
                <td>
                    <i className="material-icons-outlined" onClick={handleClickOpenDeleteModal}>
                        delete
                    </i>
                    <i className="material-icons-outlined" onClick={handleClickOpenEditModal}>
                        edit
                    </i>
                </td>
            </tr>
        </>
    );
};

export default LocationRow;
